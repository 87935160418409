import { useState } from 'react';

import './App.css';
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import MainMint from "./components/MainMint";

function App() {
  const [accounts, setAccounts] = useState([]);

  return (
    <div className="App h-full flex flex-col">
      <div className="flex-grow">
        <Navbar accounts={accounts} setAccounts={setAccounts} />
        <Hero />
        <MainMint accounts={accounts} setAccounts={setAccounts} />
      </div>
      <footer>

        <div className="container md:hidden mx-auto text-center p-4">
            Learn with our  <a className="underline" href="https://storyblocknft.gitbook.io/story-block/" target="_">GitBook</a>
        </div>
        <div className="container mx-auto text-center p-4 flex justify-center">
          <a href="https://opensea.io/collection/story-block"  target="_" className='mr-4'>
            <img src="/svgs/os-logo.svg" height="35" width="35" alt="Opensea NFT Marketplace logo" />
          </a>
          <a href="https://twitter.com/StoryblockNFT"  target="_" className='mr-4'>
            <img src="/svgs/twitter.svg" height="35" width="35" alt="Twitter logo" />
          </a>
          <a href="https://discord.gg/qUFVXNDY"  target="_" className='mr-4'>
            <img src="/svgs/discord.svg" height="35" width="35" alt="Discord logo" />
          </a>
          <a href="https://github.com/Zlerp/story-block"  target="_" className='mr-4'>
            <img src="/svgs/github.svg" height="35" width="35" alt="Github logo" />
          </a>
          <a href="https://wacdonalds.io/"  target="_" >
            <img src="/imgs/mrCryptopoly.png" height="35" width="35" alt="Wacdonald's Cryptopoly logo" />
          </a>
        </div>
        <div className="container mx-auto text-center p-4 pt-0">
          <p>Built by the creators of <a className="text-purple-500 underline" target="_"  href="https://wacdonalds.io/">WacDonald's Cryptopoly</a></p>
        </div>
      </footer>
    </div>
  );
}

export default App;
