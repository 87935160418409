import React from 'react';

function Hero() {
  return (
    <div>
      <img src="/bg.jpeg" className="w-full h-full opacity-10 fixed top-0 left-0 -z-10 object-cover object-bottom	" alt="Imagination land"/>
      <div className="container mx-auto py-8 text-center">
        <h1 className="text-2xl font-bold">STORY BLOCK</h1>
        <div className="container mx-auto text-center p-4 pt-0">
          <p>
            <span>Brought to you by the creators of</span> <a className="text-purple-500 underline"  target="_"  href="https://wacdonalds.io/">WacDonald's Cryptopoly</a>
          </p>

        </div>
      </div>
    </div>
  );
}

export default Hero;
