import {ethers} from "ethers";
import storyJson from "../contract/Story.json";

export const contractAddress = "0x0e85f0ebe129d184e6631C53348AE2746640d4B5";

let provider;
if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum)
}


export const useContract = () => {
  if (window.ethereum) {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      storyJson.abi,
      signer
    );
    return contract;
  }

}

export const getContractAddress = () => {
  // const { address } =  Deployment.contracts['Game'];
  return contractAddress;
};

export async function getBlock() {
  if (window.ethereum) {
    const blockNumber = await provider.getTime();
    return blockNumber;
  }
}
