import React, {useState, useEffect} from 'react';
import { ethers, BigNumber} from "ethers";
import { useContract, contractAddress } from '../utils/util';
import { Button, Modal } from 'flowbite-react';
import Countdown from 'react-countdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainMint = ({ accounts }) => {
  const [mintText, setMintText] = useState("");
  const [mintUpdateText, setMintUpdateText] = useState("");
  const [allTokens, setAllTokens] = useState([]);
  const [userTokens, setUserTokens] = useState([]);
  const [receipt, setReceipt] = useState({});
  const [currentPrice, setCurrentPrice] = useState("");
  const [startPrice, setStartPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [unlockBlockTime, setUnlockBlockTime] = useState(0);
  const [blockNumber, setBlockNumber] = useState(0);
  const isConnected = Boolean(accounts[0]);
  const [openModal, setOpenModal] = useState("");


  // const blockExplorer = "https://rinkeby.etherscan.io/tx/";
  // const osLink = `https://testnets.opensea.io/assets/rinkeby/${contractAddress}/`

  const blockExplorer = "https://etherscan.io/tx/";
  const osLink = `https://opensea.io/assets/ethereum/${contractAddress}/`
  const contract = useContract();

    async function handleMint() {
      if (window.ethereum) {
        try {
          setLoading(true);

          const sanitizedMintText = mintText.replace("&", "and");
          const response = await contract.mint(sanitizedMintText, {
            value: ethers.utils.parseEther((currentPrice).toString()),
          });
          const toastId = toast((
            <div>
              <span>Transaction Submitted: </span>
              <a href={`${blockExplorer}${response.hash}`} className="text-purple-600 underline" target="_blank">{response.hash.substring(0, 3)}...{response.hash.substring(response.hash.length - 3)}</a>
            </div>
          ), {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: true,
          });
            

          const newReceipt = await response.wait();          
          setReceipt(newReceipt.transactionHash);          
          toast.update(toastId, { render: (
            <div>
              <span>Transaction Completed: </span>
              <a href={`${blockExplorer}${response.hash}`} className="text-purple-600 underline" target="_blank">{response.hash.substring(0, 3)}...{response.hash.substring(response.hash.length - 3)}</a>
            </div>
          ), type: "success", isLoading: false });
          setMintText("");
        } catch (err) {
          toast.error(err.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            closeOnClick: false,
            draggable: true,
          });
        }
        setLoading(false);
      }
    }

    async function handleStoryUpdate(tokenId) {
      if (window.ethereum) {
        try {
          setLoading(true);
          const sanitizedMintUpdateText = mintUpdateText.replace("&", "and");
          const response = await contract.updateStory(parseInt(tokenId), sanitizedMintUpdateText, {
            value: ethers.utils.parseEther((0).toString()),
          });
          const newReceipt = await response.wait();
          setReceipt(newReceipt);
          setMintUpdateText("");
        } catch (err) {
          console.log("error: ", err)
        }
        setLoading(false)
      }
    }
    async function getUserTokens() {
      if (window.ethereum) {
        try {
          // console.log("Running function now!", await contract.getAllTokens());
          if (accounts.length){
            const response = await contract.getTokensByUser(accounts[0]);

            const tokensArr = [];
            // console.log(response);
            response.map(function( token, index)  {
              tokensArr.push(token["tokenId"].toString());
            });
            // console.log(tokensArr);
            setUserTokens(tokensArr);
          }
        } catch (err) {
          console.log("error: ", err)
        }
      }
    }

    async function getAllTokens() {
      if (window.ethereum) {
        try {
          // console.log("Running function now!", await contract.getAllTokens());
          const response = await contract.getAllTokens();
          // console.log(response)

          setAllTokens(response);
        } catch (err) {
          console.log("error: ", err)
        }
      }
    }


    async function getUnlockTime() {
      if (window.ethereum) {
        try {
          const response = await contract.getUnlockTime();        
          const lockBlockTime = response.toNumber();
          const currentTime = new Date();
          const unlockTime = new Date(lockBlockTime * 1000);
          setUnlockBlockTime(unlockTime);
          setIsLocked(unlockTime > currentTime);
        } catch (err) {
          console.log("error: ", err)
        }
      }
    }

    async function getCurrentPrice() {
      if (window.ethereum) {
        try {
          // console.log("Running function now!", await contract.getAllTokens());
          const response = await contract.getPrice();
  
  
          if (isLocked) {
            // console.log("is locked");
            setCurrentPrice(0);
          } else {
            // console.log(ethers.utils.formatEther(response))
            // console.log(ethers.utils.formatEther(response, {pad: true}).toString());
            setCurrentPrice(ethers.utils.formatEther(response).toString());
          }
  
          // console.log(ethers.utils.formatEther(BigNumber.from(response)).toString());
        } catch (err) {
          console.log("error: ", err)
        }
      }
    }

    async function getStartPrice() {
      if (window.ethereum) {
        try {
          const response = await contract.getStartPrice();
          setStartPrice(ethers.utils.formatEther(response).toString());
  
        } catch (err) {
          console.log("error: ", err)
        }
      }
    }
  const handleTextChange = (e) => {
    setMintText(e.target.value);
  }


  const handleTextUpdateChange = (e) => {
    setMintUpdateText(e.target.value);
  }

  // const renderTime = (timestamp) => {
  //   if (timestamp) {
  //     let options = {  month: 'numeric', day: 'numeric', year: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric'};
  //     const time = new Date(timestamp);
  //     const timeString = time.toLocaleString(undefined, options);
  //     return timeString;
  //   }
  //   return "Bye";
  // }

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>0</span>;
  } else {
    // Render a countdown
    return (<span>{`${hours > 0 ? `${hours}:` : ""}`}{minutes}:{ seconds.toString().length < 2 ? `0${seconds}` : seconds }</span>);
  }
};




    useEffect(() => {
      if (window.ethereum) {        
        getAllTokens();
        getUserTokens();
        getCurrentPrice();
        getUnlockTime();
        getStartPrice();
      }
    }, [loading, receipt, isConnected ]);

  useEffect(() => {
    if (window.ethereum) {
      getCurrentPrice();
      getUnlockTime();
      getStartPrice();
    }
  }, [blockNumber, isLocked]);

  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    provider.on("block", (tblockNumber) => {
      setBlockNumber(tblockNumber)
    });
  }



  return (
    <div className="container mx-auto max-w-[800px] px-4 pb-8"> 

      {isConnected ? (
        <div>

          <h3 className="text-center text-lg font-medium mb-4">Once upon a time, in Cryptoland...</h3>
          <p className='mt-2 max-w-[600px] mx-auto text-center mb-2 text-sm'>Reverse Dutch auction, starting at 0.111ETH and will go to 0ETH over 2 hours.</p>
          <div className="flex items-center max-w-[600px] mx-auto">
            <input type="text" id="default-input" onChange={handleTextChange}  value={mintText} placeholder={allTokens.length > 0 ? `continue the journey...` : 'start the adventure!'} className="text-base min-w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
              <button onClick={handleMint} type="button"
                    disabled={loading || isLocked || mintText.length < 4 || mintText.length > 193 }
                    className={`${(loading || isLocked) ? "!text-gray-400 !hover:bg-white" : ""} text-base ml-2 h-full whitespace-nowrap min-w-auto py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700`}>
                          { isLocked ? (
                          <span>Writers Block</span>
                        ) : (
                          <span>Add line</span>
                        ) 
                      }
              </button>              
          </div>
          <div className='flex max-w-[600px] mx-auto justify-between text-sm mt-2'>
            <div>
              { mintText.length > 0 && mintText.length < 4 &&              
                <p className="text-center text-red-500 mr-4">Min 4 characters</p> 
              }
              { mintText.length > 193 &&              
                <p className="text-center text-red-500 mr-4">Max 193 characters</p> 
              }
            </div>
            <p className="text-center">Character length: {mintText.length}</p> 
          </div>
          <div className="text-center text-sm p-2 max-w-[600px] mx-auto">
            { isLocked ? (
              <div>
                <div className="flex justify-between">
                  <p>Current Price: {startPrice}</p>
                  <p className="mb-2">Unlocks in: <Countdown date={new Date(unlockBlockTime) } renderer={renderer} /></p>
                </div>              
                <p>All of minting is locked halted the unlock time. No one else can mint either, chillz.</p>
                <p>This is blockchain, so time goes by <a className="underline text-purple-500" target="blank" href="https://ethereum.org/en/developers/docs/blocks/#block-time">block time</a>. It could be a few seconds fast/slow.</p>
              </div>
            ) : (
              <div>
                <p>Current Price: {currentPrice}</p>
              </div>
            )
              
            }
          </div>


          <div className="text-left mt-16">
            {
              allTokens.map(function(token, index){
                return(

                  <span key={index}>
                      { (index % 5  === 0 && index !== 0)  &&
                        <span>
                        <br /><br />
                        </span>

                      }


                    { userTokens.includes(token["tokenId"].toString()) ? (
                      <div className="inline">
                          <a onClick={() => setOpenModal(`default${token["tokenId"].toString()}`)} className="text-base	inline hover:underline text-purple-600 cursor-pointer">{token["tokenText"].match(/[.,:!?]$/) ? token["tokenText"] + " " : token["tokenText"].trim() +". "}</a>
                          <Modal show={openModal === `default${token["tokenId"].toString()}`} onClose={() => setOpenModal(undefined)}>
                            <Modal.Header>Update Token #{token["tokenId"].toString()}</Modal.Header>
                            <Modal.Body>
                              <div className="space-y-6">
                                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                  Update this token with a new story, preferably within the context of the theme.
                                </p>
                                <input type="text" id="default-input" onChange={handleTextUpdateChange}  value={mintUpdateText} placeholder={token["tokenText"]} className="text-base	min-w-auto bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button onClick={() => {handleStoryUpdate(token["tokenId"].toString()); setOpenModal(undefined);}} type="button"
                                      disabled={loading}>Update Story</Button>
                              <Button href={osLink + token["tokenId"].toString()} target="_blank" color="gray">
                                <span className="mr-2">View on Opensea </span>
                                <img src="/svgs/os-logo.svg" height="20" width="20" alt="Opensea NFT Marketplace logo"/>
                              </Button>
                            </Modal.Footer>
                          </Modal>
                      </div>

                      ) : (
                        <a key={index} href={`${osLink}${BigNumber.from(token["tokenId"])}`} target="_blank" className="text-base	inline mr-1 hover:underline">{token["tokenText"]}{token["tokenText"].match(/[.,:!?]$/) ? "" : "."}</a>
                      )}
                  </span>

                )
              })
            }

          </div>

          <ToastContainer />

        </div>
        ) : (
        <div>
          <h3 className="text-center text-lg font-medium mb-4">What is Story Block?</h3>
          <p className="mb-6">
            Story Block is the first all on-chain experiment in collaborative storytelling. 
            <br/><br/>
            By concatenating, or linking, all of the StoryBlock NFTs together a story is formed, written by the token holders of the community. 
            <br/><br/>
            The goal is simply to see what sort of story the crypto community can put together.
            <br/><br/>
            If this project and community grows, we will continue to develop and expand this together.
            <br/><br/>
            Each NFT acts as a sentence that will be appended to the end of the last line. The sentences will always be in the order of when they were minted. The user that mints the NFT originally will need to add a sentence to complete the transaction. The user that owns the token can update the sentence at any time.
            <br/><br/>
            This collection is created using SVGs, meaning all NFT image and metadata are forever stored on Ethereum's blockchain.
            <br/><br/>
            We built a fun experiment that I wanted to share with you guys first. The hope is that people can collaborate to literally write a story on chain.
            <br/><br/>
            The total collection is 888 NFTs. It functions on a reverse Dutch auction that goes from 0.111ETH to 0ETH over 2 hours.
            <br/><br/>
            This is ultimately what defines Story Block: what story will the users of Ethereum create?
          </p>
          <strong>Rules to keep it fun for everyone</strong>
          <ol className="list-decimal pl-6 mb-12">
            <li>Don't be a Dick.</li>
            <li>Try to keep continuity.</li>
            <li>Have Fun with it.</li>
          </ol>

          <p className="text-center">You must be connected to continue.</p>
        </div>

      )}
    </div>
  )
}

export default MainMint;
